import { publicUrlFor, route } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import PaidIcon from '@mui/icons-material/Paid';
function SectionHome2Video() {
  return (
    <div className="section-full p-t80 p-b50 site-bg-white" id="Benefits">
      <div className="container">
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2>Benefits</h2>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}
        <div className="section-content">
          <div className="row">
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-b30">
              <div className="mt-icon-box-wraper bx-style-1 p-a30 center site-bg-white ">
                <div className="mt-icon-box-sm site-bg-primary m-b20  radius-md">
                  <span className="icon-cell text-white">
                    {/* <i className="fa fa-html5" /> */}
                    <HealthAndSafetyIcon className="fa " />
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="mt-tilte">SAFETY</h4>
                  <p>
                    Safety is not just a priority at Ground Force Trucking; it's a fundamental core value that drives our operations. Our unwavering commitment to safety ensures not only the protection of our valuable cargo but also the well-being of our dedicated team members and the communities we serve.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-b30">
              <div className="mt-icon-box-wraper bx-style-1 p-a30 center site-bg-white ">
                <div className="mt-icon-box-sm radius site-bg-primary m-b20">
                  <span className="icon-cell text-white">
                    {/* <i className="fa fa-css3" /> */}
                    <LoyaltyIcon />
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="mt-tilte">BENEFITS</h4>
                  <p>
                    At Ground Force Trucking, we hold our people in the highest regard, recognizing that they are the backbone of our success. One of the ways we demonstrate this appreciation is by providing a comprehensive benefits package, which includes medical and dental coverage, paid time off, and a robust 401k plan (4% company match), designed to support our employees' well-being and long-term financial security.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-6 col-xs-100pc m-b30">
              <div className="mt-icon-box-wraper bx-style-1 p-a30 center site-bg-white ">
                <div className="mt-icon-box-sm radius site-bg-primary m-b20">
                  <span className="icon-cell text-white">
                    {/* <i className="fa fa-css3" /> */}
                    <PaidIcon />
                  </span>
                </div>
                <div className="icon-content">
                  <h4 className="mt-tilte">
                    PAY</h4>
                  <p>
                    Ground Force Trucking proudly stands as one of the top paying contractors in the industry. We understand that offering competitive pay is not just a perk but a pivotal component of our business model, attracting and retaining the most skilled professionals who share our commitment to excellence in transportation services.
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome2Video;
