import { route } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";

function SectionHome3WhyChooseUs() {
  return (
    <div className="section-full p-t80 p-b0 bg-gray" id="Aboutus">
      <div className="container">
        {/* TITLE START */}
        <div className="section-head text-center">
          <h2 >About Us</h2>
          <div className="mt-separator-outer">
            <div className="mt-separator site-bg-primary" />
          </div>
        </div>
        {/* TITLE END */}
        <div className="row">

          <div className="col-md-12  m-b50">
            <div className="section-head">
              {/* <h4>With Bg Gray</h4> */}
            </div>
            {/*  Accordian Background Gary */}
            <div className="section-content p-b30">
              <div
                className="accordion mt-accordion acc-bg-gray"
                id="accordion5-1"
              >
                {/*one*/}
                <div className="acod-head mt-panel acc-bg-gray">
                  <h6
                    className="accordion-header acod-title text-uppercase corner-skew bdr-l-2 bdr-solid bdr-primary"
                    id="headingOne"
                  >
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseOne-ex2"
                      aria-expanded="false"
                      aria-controls="collapseOne-ex2"
                    >
                      <i className="fa fa-globe" />
                      A Family-Owned Legacy
                      <span className="indicator">
                        <i className="fa fa-plus" />
                      </span>
                    </a>
                  </h6>
                  <div
                    id="collapseOne-ex2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingOne"
                    data-bs-parent="#accordion5-1"
                  >
                    <div className="acod-body">
                      <div className="acod-content p-tb15">
                        Join a company where you're not just an employee but a valued member of the family. At Ground Force Trucking, we foster a sense of belonging, support, and camaraderie, making every team member an integral part of our family-owned trucking legacy.
                      </div>
                    </div>
                  </div>
                </div>
                {/*Two*/}
                <div className="acod-head mt-panel">
                  <h6
                    className="accordion-header acod-title text-uppercase corner-skew bdr-l-2 bdr-solid bdr-primary"
                    id="headingTwo"
                  >
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo-ex2"
                      aria-expanded="false"
                      aria-controls="collapseTwo-ex2"
                    >
                      <i className="fa fa-photo" />
                      Safety First, Always
                      <span className="indicator">
                        <i className="fa fa-plus" />
                      </span>
                    </a>
                  </h6>
                  <div
                    id="collapseTwo-ex2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordion5-1"
                  >
                    <div className="acod-body">
                      <div className="acod-content p-tb15">
                        At Ground Force Trucking, we make safety our top priority. From state-of-the-art vehicle maintenance to comprehensive driver training programs, we ensure that every mile is a safe one for our drivers and the cargo they carry.
                      </div>
                    </div>
                  </div>
                </div>
                {/*Three*/}
                <div className="acod-head mt-panel">
                  <h6
                    className="accordion-header  acod-title text-uppercase corner-skew bdr-l-2 bdr-solid bdr-primary"
                    id="headingThree"
                  >
                    <a
                      href="#"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseThree-ex2"
                      aria-expanded="false"
                      aria-controls="collapseThree-ex2"
                    >
                      <i className="fa fa-cog" />
                      Driven by Respect
                      <span className="indicator">
                        <i className="fa fa-plus" />
                      </span>
                    </a>
                  </h6>
                  <div
                    id="collapseThree-ex2"
                    className="accordion-collapse collapse"
                    aria-labelledby="headingThree"
                    data-bs-parent="#accordion5-1"
                  >
                    <div className="acod-body">
                      <div className="acod-content p-tb15">
                        We believe in treating our team with the utmost respect and consideration. At Ground Force Trucking, we provide a work environment where every employee is appreciated, supported, and recognized for their hard work and dedication to our shared success.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome3WhyChooseUs;
