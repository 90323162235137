  import { useEffect, useState } from "react";
  import { loadScript, route, publicUrlFor } from "../../../globals/constants";
  import FastImage from "../../../globals/elements/fastimg";
  import CountUp from "react-countup";
  import SectionSlider1 from "../../sections/home/home1/section-slider1";
  import { NavLink } from "react-router-dom";
  import SectionHome1Team from "../../sections/home/home1/section-home1-team";
  import SectionHome1Plans from "../../sections/home/home1/section-home1-plans";
  import SectionHome1Blogs from "../../sections/home/home1/section-home1-blogs";
  import SectionHome1Testimonials from "../../sections/home/home1/section-home1-testimonials";
  import _data from "../../../globals/data/data.json";

  import { Button, Form, Input, Radio, Select, message } from "antd";
  // import emailjs from 'emailjs-com';
  import emailjs from 'emailjs-com';
  emailjs.init("OE__t6fGuIYBEVsbx");

  // emailjs.init("user_your_emailjs_user_id");

  function Home1Page() {
    const { clients } = _data;
    const [form] = Form.useForm();

    useEffect(() => {
      loadScript("js/custom.js");
    });
    const handleFormSubmit = async (values) => {
      const { username, email, phone, fedexLocation, doublesEndorsement, classADriverLicense, cldExperience } = values;
    
      // Format email content (if needed separately)
      const emailContent = `
        Name: ${username}
        Email: ${email}
        Phone: ${phone}
        FedEx Location: ${fedexLocation}
        Doubles Endorsement: ${doublesEndorsement}
        Class A Driver’s License: ${classADriverLicense}
        CLD Class A Experience: ${cldExperience}
      `;
    
      // Configure email settings
      const emailSettings = {
        to: "j_brown@groundforcetrucking.com", // Replace with your email address
        from: email,
        subject: "Contact Form Submission",
        body: emailContent,
      };
    
      // Get the form element directly
      const formElement = document.querySelector('form'); // Replace 'form' with your actual form selector if needed
    
      try {
        await emailjs.sendForm("service_1i2ryzq", "template_gezoznk", formElement);
       message.success("Email sent successfully!")
        console.log("Email sent successfully!");
        // Clear form data or display success message
      } catch (error) {
        console.error("Error sending email:", error);
        // Handle email sending errors
      }
    };
    return (
      <>
        {/* Contact Us  */}
        <div
          id="Apply"
          className="section-full bg-white bg-right-center bg-no-repeat"
        >
          <h2 className="text-center">Apply Now</h2>

          <div className="container">
            <div className="row conntact-home">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="contact-home1-left bg-secondry">
                  <div className="section-content p-lr30 p-tb30">
                    <div
                      className="call-back-form"
                      style={{ zIndex: 1, position: "relative" }}
                    >
                      <Form
                        form={form}
                        className="cons-contact-form2 form-transparent"
                        onFinish={handleFormSubmit}
                        layout={"vertical"}
                      >
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                              label="Name"
                              name="username"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your name",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Email"
                              name="email"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your email",
                                },
                              ]}
                            >
                              <Input type="email" />
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                              label="Phone"
                              name="phone"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter your phone",
                                },
                              ]}
                            >
                              <Input />
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Which FedEx location are you interested in working out of?"
                              name="fedexLocation"
                            >
                              <Select placeholder="Select an option">
                                <Select.Option value="Dallas, TX">Dallas, TX</Select.Option>
                                <Select.Option value="Cypress, TX">Cypress, TX</Select.Option>
                                <Select.Option value="Tracy, CA">Tracy, CA</Select.Option>
                              </Select>
                            </Form.Item>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <Form.Item
                              label="Do you have your doubles endorsement?"
                              name="doublesEndorsement"
                            // initialValue="Yes"
                            >
                              <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                          <div className="col-md-6">
                            <Form.Item
                              label="Do you currently have a Class A driver’s license?"
                              name="classADriverLicense"
                            // initialValue="Yes"
                            >
                              <Radio.Group>
                                <Radio value="Yes">Yes</Radio>
                                <Radio value="No">No</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </div>
                        </div>

                        <Form.Item
                          label="How many years of CLD class A experience do you have?"
                          name="cldExperience"
                        // initialValue="Less than 1 year"
                        >
                          <Radio.Group>
                            <Radio value="Less than 1 year">
                              Less than 1 year
                            </Radio>
                            <Radio value="1-3 years">1-3 years</Radio>
                            <Radio value="3-5 years">3-5 years</Radio>
                            <Radio value="More than 5 years">
                              More than 5 years
                            </Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item>
                          <Button style={{ height: 'auto' }} htmlType="submit"
                            className="site-button button-lg pt-2 pb-2  m-r15"

                          >
                            Submit
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  export default Home1Page;
