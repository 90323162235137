import { useEffect } from "react";
import { loadScript, publicUrlFor, route } from "../../../globals/constants";
import FastImage from "../../../globals/elements/fastimg";
import SectionSlider3 from "../../sections/home/home3/section-slider3";
import SectionHome3WhatWeDo from "../../sections/home/home3/section-home3-what-we-do";
import SectionHome3WhyChooseUs from "../../sections/home/home3/section-home3-why-choose-us";
import SectionHome2Video from "../../sections/home/home2/section-home2-video";
import SectionHome1Team from "../../sections/home/home1/section-home1-team";
import SectionHome1Blogs from "../../sections/home/home1/section-home1-blogs";
import { NavLink } from "react-router-dom";
import _data from "../../../globals/data/data.json";
import Home1Page from "./home1";

function Home3Page() {

    const { clients } = _data;

    useEffect(() => {
        loadScript("js/custom.js");
    })

    return (
        <>
            {/* Slider */}
            <SectionSlider3 />

            {/* Our Mission */}
            <SectionHome3WhatWeDo />

            {/* Why Choose us */}
            <SectionHome3WhyChooseUs />

            {/*Video Section */}
            <SectionHome2Video />


            {/* Our Team */}
            {/* <SectionHome1Team /> */}

            {/* Our Services */}

            {/* MY BLOG */}
            {/* <SectionHome1Blogs /> */}
            <Home1Page />
            {/* Our Client Logo */}

        </>
    )
}
export default Home3Page;