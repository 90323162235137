import FastImage from "../../../../globals/elements/fastimg";
import { loadScript, publicUrlFor, route } from "../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import heroImage from "../../../../../src/767577be-52f9-4509-a599-658a5dabe88a.jpg"
function SectionSlider3() {


  return (
    <>
      <div className="main-slider style-two default-banner  "
        id="home"
      >
        <div className="container-fluid mt-4 mb-4">

          <div className="row align-items-center">
            <div className="col-md-6">
              <div className="">
                <div className="  ">

                  <div
                    className="px-4"

                  >

                    <div

                    // id="rev_slider_1014_1"
                    // className="rev_slider fullscreenbanner "

                    >
                      <p className="site-text-primary home-heading mb-4">
                        Ground Force Trucking

                      </p>
                      <div
                        className=" "
                      // id="slide-100-layer-8"

                      >
                        <span className="text-black" style={{ fontFamily: '"Poppins", sans-serif', fontSize: 20, lineHeight: 1.3 }}>
                          We take pride in putting our employees first. As a
                          trusted contractor For FedEx, Ground Force Trucking has
                          a long-standing commitment to delivering excellence in
                          logistics. Our success is rooted in our core values, and
                          at the heart of it all are our dedicated employees.
                          Together, at Ground Force Trucking, we drive not just
                          shipments but also opportunities, growth and a brighter
                          future for all.
                        </span>
                      </div>
                      <div
                        className="mt-5 w-100 text-center"

                        style={{
                          zIndex: 13,
                          textTransform: "uppercase",
                          fontWeight: 700,
                        }}
                      >
                        <a
                          // to={route.pages.ABOUT}
                          // href:"Aboutus"
                          href="#Aboutus"
                          className="site-button button-lg  m-r15"
                        >
                          Read more
                        </a>
                      </div>
                      <div
                        className="tp-bannertimer tp-bottom"
                        style={{ visibility: "hidden !important" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 ">
              <img className="w-100 rounded" src={heroImage} />
            </div>
          </div>
        </div>

      </div>
    </>
  );
}
export default SectionSlider3;
