import { NavLink } from "react-router-dom";
import { loadScript, route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { useEffect, useState } from "react";

function SectionHeader3() {

    const [isActive, setIsActive] = useState(false);

    useEffect(() => {
        loadScript("js/sticky-header.js");
    })

    const handleToggleMenu = () => {
        setIsActive(!isActive);
    }

    return (
        <header className={"site-header header-style-3 mobile-sider-drawer-menu " + (isActive ? 'active' : '')}>
            {/* Search Link */}
            <div className="main-bar header-middle bg-white">
                <div className="container-fluid">
                    <div className="logo-header hide-skew-section">
                        <NavLink to={route.home.HOME1}>
                            <FastImage src="images/logotr2.png" width={116} height={10} alt="" />
                        </NavLink>
                    </div>
                    <div className="header-info">
                        <ul>
                            <li>
                                <div>
                                    <div className="icon-sm">
                                        <span className="icon-cell  site-text-primary"><i className="iconmoon-smartphone" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <strong>Phone Number</strong>
                                        <span>(423) 208-8818</span>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div>
                                    <div className="icon-sm">
                                        <span className="icon-cell  site-text-primary"><i className="iconmoon-envelope" /></span>
                                    </div>
                                    <div className="icon-content">
                                        <strong>Email Address</strong>
                                        <span>j_brown@groundforcetrucking.com</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="sticky-header main-bar-wraper">
                <div className="main-bar header-botton nav-bg-primary">
                    <div className="container-fluid">
                        {/* NAV Toggle Button */}
                        <button id="mobile-side-drawer" data-target=".header-nav" data-toggle="collapse" type="button" className="navbar-toggler collapsed" onClick={handleToggleMenu}>
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button>
                        {/* ETRA Nav */}

                        {/* SITE Search */}
                        {/* <div id="search">
                            <span className="close" />
                            <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                                <div className="input-group">
                                    <input defaultValue="" name="q" type="search" placeholder="Type to search" />
                                    <span className="input-group-btn"><button type="button" className="search-btn"><i className="fa fa-search" /></button></span>
                                </div>
                            </form>
                        </div> */}
                        <div className="logo-show-in-mobile">
                            <NavLink to={route.home.HOME1}>
                                <FastImage src="images/logotr2.png" width={116} height={20} alt="" />
                            </NavLink>
                        </div>
                        {/* MAIN Vav */}
                        <div className="header-nav navbar-collapse collapse align-items-end">
                            <ul className=" nav navbar-nav">
                                {/* <li className="active">
                                    <a href="#">Home<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        <li><NavLink to={route.home.HOME1}>Home-1</NavLink></li>
                                        <li><NavLink to={route.home.HOME2}>Home-2</NavLink></li>
                                        <li><NavLink to={route.home.HOME3}>Home-3</NavLink></li>
                                    </ul>
                                </li> */}
                                {/* <li>
                                    <a href="#">Pages<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        <li><NavLink to={route.pages.ABOUT}>About us</NavLink></li>
                                        <li><NavLink to={route.pages.FAQ}>FAQ</NavLink></li>
                                        <li><a href="#">Our Team</a>
                                            <ul className="sub-menu">
                                                <li><NavLink to={route.pages.team.TEAM}>Our Team</NavLink></li>
                                                <li><NavLink to={route.pages.team.DETAIL}>Our Team Detail</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Contact</a>
                                            <ul className="sub-menu">
                                                <li><NavLink to={route.pages.contact.CONTACT1}>Contact us 1</NavLink></li>
                                                <li><NavLink to={route.pages.contact.CONTACT2}>Contact us 2</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><a href="#">Galley</a>
                                            <ul className="sub-menu">
                                                <li><NavLink to={route.pages.gallery.GALLERY1}>Galley 1</NavLink></li>
                                                <li><NavLink to={route.pages.gallery.GALLERY2}>Galley 2</NavLink></li>
                                            </ul>
                                        </li>
                                        <li><NavLink to={route.pages.ERROR404}>Error 404</NavLink></li>
                                        <li><a href="#">Features</a>
                                            <ul className="sub-menu">
                                                <li><NavLink to={route.pages.features.footer.FIXED}>Fixed Footer</NavLink></li>
                                                <li><NavLink to={route.pages.features.footer.LIGHT}>Light Footer</NavLink></li>
                                                <li><NavLink to={route.pages.features.footer.DARK}>Dark Footer</NavLink></li>
                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Services<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        <li><NavLink to={route.services.SERVICES}>Services</NavLink></li>
                                        <li><NavLink to={route.services.DETAIL}>Services Detail</NavLink></li>
                                    </ul>
                                </li> */}
                                {/* <li>
                                    <a href="#">Blog<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        <li><NavLink to={route.blog.LIST}>Blog-List</NavLink></li>
                                        <li><NavLink to={route.blog.LIST_SIDEBAR}>Blog-List-Sidebar</NavLink></li>
                                        <li><NavLink to={route.blog.GRID}>Blog-Grid</NavLink></li>
                                        <li><NavLink to={route.blog.GRID_SIDEBAR}>Blog-Grid-Sidebar</NavLink></li>
                                        <li><NavLink to={route.blog.POST_SINGLE}>Blog-Post-single</NavLink></li>
                                    </ul>
                                </li>
                                <li>
                                    <a href="#">Shop<i className="fa fa-chevron-down" /></a>
                                    <ul className="sub-menu">
                                        <li><NavLink to={route.shop.PRODUCTS}>Product</NavLink></li>
                                        <li><NavLink to={route.shop.DETAIL}>Product Detail</NavLink></li>
                                        <li><NavLink to={route.shop.CART}>Shopping cart</NavLink></li>
                                        <li><NavLink to={route.shop.WISHLIST}>Wishlist</NavLink></li>
                                        <li><NavLink to={route.shop.CHECKOUT}>Checkout</NavLink></li>
                                    </ul>
                                </li> */}
                                <li>
                                    <a href="#home">Home</a>
                                </li>
                                <li>
                                    {/* <NavLink to={route.ELEMENTS}>About us</NavLink> */}
                                    <a href="#Aboutus">About us</a>

                                </li>
                                <li>
                                    {/* <NavLink to={route.ELEMENTS}>Benefits </NavLink> */}
                                    <a href="#Benefits">Benefits</a>

                                </li>
                                <li>
                                    {/* <NavLink to={route.ELEMENTS}>Apply Now  </NavLink> */}
                                    <a href="#Apply">Apply Now</a>

                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>

    )
}
export default SectionHeader3;