import { route } from "../../../../globals/constants";
import FastImage from "../../../../globals/elements/fastimg";
import { NavLink } from "react-router-dom";

function SectionHome3WhatWeDo() {
  return (
    <div className="section-full  p-t80 p-b50">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-12">
            <div className="mt-box m-b30">
              <div className="mt-thum-bx mt-img-effect">
                <FastImage src="images/gallery/pic4.jpg" alt="" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12">
            <div className="mt-box sx-what-wedo">
              <span className="site-text-primary display-block title-second text-uppercase font-20 font-weight-600 display-block m-b15">
                What we do
              </span>
              <h2 className="m-t0">
                Our Mission is to be the Best in Transport
              </h2>
              <p>
                We are a FedEx Ground contractor, dedicated to delivering
                best-in-class service with an unwavering commitment to safety,
                ensuring every package arrives securely and on time.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SectionHome3WhatWeDo;
